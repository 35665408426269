.RoadmapKey {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-m);
  align-items: flex-start;
  margin: 0 auto;
}

.RoadmapKey .KeyItem {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
  align-items: center;
}

.RoadmapKey .KeyItem .KeyItemColour {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}
