.Select:hover .Select-selector {
  border: 1px solid var(--color-grey-20);
}

.Select-focused:not(.Select-disabled).Select:not(.Select-customize-input) .Select-selector {
  box-shadow: none;
  border-color: var(--color-grey-20);
}

.Select-open.Select .Select-selection-item {
  color: var(--color-grey);
}

.Select-single.Select-disabled {
  color: var(--color-grey-40);
}

.Select-single .Select-selector .Select-selection-item {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Select-selection-item {
  flex: 1;
  overflow: hidden;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Select-single .Select-selector {
  display: flex;
}

.Select {
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: block;
  cursor: pointer;
  color: var(--color-grey);
  font-size: var(--font-size-body1);
  font-weight: 400;
  line-height: var(--line-height-body1);
}

.Select-item-option-active:not(.Select-item-option-disabled) {
  background-color: var(--color-light-grey);
}

.Select-item.Select-item-option-selected {
  color: var(--color-blue);
  font-weight: 700;
}

.Select-item {
  position: relative;
  display: block;
  min-height: var(--spacing-ll);
  padding: 0 var(--spacing-m);
  color: var(--color-grey);
  font-weight: normal;
  font-size: var(--font-size-body1);
  line-height: var(--spacing-ll);
  cursor: pointer;
  transition: background 0.3s ease;
  background-color: var(--color-white);
}

.Select-item-option-content {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Select-item-option-state {
  flex: none;
}

.Select-dropdown {
  margin: var(--spacing-m) 0 0 0;
  color: var(--color-grey);
  line-height: var(--line-height-body1);
  list-style: none;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  padding: var(--spacing-s) 0;
  overflow: hidden;
  font-variant: initial;
  background-color: var(--color-white);
  border-radius: 0;
  outline: none;
  box-shadow: none;
}

.Select-dropdown-hidden {
  display: none;
}

.Select-item-option-state-icon {
  display: none;
}

.Select:not(.Select-customize-input) .Select-selector {
  position: relative;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-20);
  border-radius: 0;
  transition: none;
}

.Select-single:not(.Select-customize-input) .Select-selector {
  width: 100%;
  height: var(--spacing-xl);
  padding: 0 var(--spacing-m);
}

.Select-single .Select-selector .Select-selection-search {
  position: absolute;
  top: 0;
  right: var(--spacing-m);
  bottom: 0;
  left: var(--spacing-m);
}

.Select:not(.Select-customize-input) .Select-selector .Select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}

.Select-single:not(.Select-customize-input) .Select-selector .Select-selection-search-input {
  height: var(--spacing-xl);
}

.Select-single .Select-selector .Select-selection-wrap {
  width: 90%;
}

.Select:not(.Select-customize-input) .Select-selector input {
  cursor: pointer;
}

.Select-single .Select-selector .Select-selection-search-input {
  width: 100%;
}

.Select-single .Select-selector .Select-selection-item {
  position: relative;
  user-select: none;
  width: 100%;
}

.Select-selection-item {
  flex: 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Select-single .Select-selector .Select-selection-item,
.Select-single .Select-selector .Select-selection-placeholder {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: var(--spacing-xl);
}

.Select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: var(--color-grey-20);
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.Select-arrow {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: var(--spacing-m);
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: var(--color-grey-20);
  font-size: var(--font-size-body3);
  line-height: 1;
  pointer-events: none;
}
