.ProjectListingScoreKPI {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.ProjectListingScoreTooltip {
  width: 168px;
  padding: var(--spacing-m) 0 0 0;
}

.ProjectListingScoreTooltip .ProjectListingScoreTooltipSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--spacing-s);
  padding: 0 0 var(--spacing-m) 0;
}

.ProjectListingScoreTooltip .ProjectListingScoreTooltipSection .TickIcon {
  margin-left: auto;
}
