.Tabs {
  height: 40px;
  display: flex;
  gap: var(--spacing-xs);
}

.Tabs .TabElement {
  line-height: var(--line-height-h1-tablet);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: var(--color-white);
  color: var(--color-grey);
}

.Tabs .TabElement_medium {
  width: 170px;
}

.Tabs .TabElement:not(.TabElement_active):hover {
  background-color: var(--color-blue-20);
  font-weight: bold;
}

.Tabs .TabElement_active {
  font-weight: 700;
  color: var(--color-blue);
  border-bottom: var(--spacing-xs) solid var(--color-blue);
}

.Tabs .TabElement_DevelopmentManager.TabElement_active {
  border-bottom: var(--spacing-xxs) solid var(--color-blue);
}

.TabsContent {
  background-color: var(--color-white);
  padding: var(--spacing-2xl);
}
