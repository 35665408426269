.ProjectTrackerContainer .ProjectTrackerButtons {
  column-gap: var(--spacing-m);
}
.ProjectTrackerContainer .ProjectTrackerButtons svg.ArrowDownDark > path {
  stroke: var(--color-blue);
}
.ProjectTrackerContainer .ProjectTrackerButtons .ProfileMenuContainer {
  width: 160px;
}

.ProjectTrackerContainer .ProjectTrackerDataGrid {
  height: 552px;
}
.ProjectTrackerContainer .ProjectTrackerDataGrid.NoData {
  padding-top: var(--spacing-2xl);
}

.ProjectTrackerContainer .ProjectTrackerFilters {
  position: relative;
  top: var(--spacing-ll);
  width: fit-content;
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-ml);
  align-items: baseline;
}
.ProjectTrackerContainer .ProjectTrackerFilters h3 {
  position: relative;
  top: 38px;
}
.ProjectTrackerContainer .ProjectTrackerFilters .TagsFilter,
.ProjectTrackerContainer .ProjectTrackerFilters .QuickFilter {
  width: 199px;
}
