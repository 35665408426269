.TagsPanelContent {
  margin-top: var(--spacing-ml);
}

.TagsPanelContent .NoTags {
  color: var(--color-grey-60);
}

.TagsPanelContent .TagNameAndCheckbox {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-m);
  align-items: center;
}

.TagsPanelContent .TagNameAndCheckbox .TextInput {
  width: 100%;
}
.TagsPanelContent .TagNameAndCheckbox .TextInput input {
  max-height: 40px;
}

.TagsPanelContent .AddTagButton {
  margin-top: var(--spacing-m);
  min-width: unset;
}
.TagsPanelContent .AddTagButton > svg > path {
  stroke: var(--color-blue);
}

.ManageTagsButton {
  min-width: 92px;
  min-height: var(--spacing-l);
}
