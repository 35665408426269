.QuickViewOptions {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
}

.QuickViewOptions .Button {
  padding: var(--spacing-sl) var(--spacing-m);
  min-width: 50px;
  border-radius: 8px;
  border: 1px solid var(--color-grey-20);
}
