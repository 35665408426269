.ScrollablePillsContainer {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
  align-items: baseline;
}

.ScrollablePillsContainer .TextLink {
  margin-left: auto;
}
.ScrollablePillsContainer .TextLink:hover p {
  color: var(--color-blue-60);
}

.ScrollablePillsContainer .ScrollablePills {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
  overflow-x: auto;
  scroll-behavior: smooth;
}

.ScrollablePillsContainer .ScrollablePills::-webkit-scrollbar {
  height: 4px;
}
.ScrollablePillsContainer .ScrollablePills::-webkit-scrollbar-thumb {
  background: var(--color-white);
  box-shadow: inset 0 2px var(--color-grey-40);
}
