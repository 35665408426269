.ProjectRoadmapPageContainer .PopulatedRoadmap {
  display: flex;
  flex-direction: column;
}

.ProjectRoadmapPageContainer .PopulatedRoadmap .EditProjectsDropdownContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-left: auto;

  position: relative;
  top: var(--spacing-xl);
}

.ProjectRoadmapPageContainer .PopulatedRoadmap .EditProjectsDropdownContainer .EditProjectsButton {
  width: 170px;
}

.ProjectRoadmapPageContainer .EmptyRoadmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: var(--spacing-l);
}

.ProjectRoadmapPageContainer .EmptyRoadmap p {
  color: var(--color-grey-60);
}

.ProjectRoadmapPageContainer .EmptyRoadmap .AddProjectsButton {
  margin-top: var(--spacing-m);
}

.ProjectRoadmapPageContainer .EmptyRoadmap .AddProjectsButton svg {
  width: 12px;
  height: 12px;
  padding-left: var(--spacing-sl);
}

.ProjectRoadmapPageContainer .EmptyRoadmap .AddProjectsButton svg > path {
  stroke: var(--color-white);
  stroke-width: 3px;
}
