/**
 * Row
 */

.Row_spacing_xs {
  margin-bottom: var(--spacing-xs);
}
.Row_spacing_s {
  margin-bottom: var(--spacing-s);
}
.Row_spacing_sl {
  margin-bottom: var(--spacing-sl);
}
.Row_spacing_m {
  margin-bottom: var(--spacing-m);
}
.Row_spacing_mm {
  margin-bottom: var(--spacing-mm);
}
.Row_spacing_ml {
  margin-bottom: var(--spacing-ml);
}
.Row_spacing_l {
  margin-bottom: var(--spacing-l);
}
.Row_spacing_ll {
  margin-bottom: var(--spacing-ll);
}
.Row_spacing_xl {
  margin-bottom: var(--spacing-xl);
}
.Row_spacing_2xl {
  margin-bottom: var(--spacing-2xl);
}
.Row_spacing_3xl {
  margin-bottom: var(--spacing-3xl);
}
.Row_spacing_4xl {
  margin-bottom: var(--spacing-4xl);
}
.Row_spacing_5xl {
  margin-bottom: var(--spacing-5xl);
}

.Row {
  flex-direction: row;
  display: flex;
  flex: initial;
  flex-wrap: wrap;

  margin-left: -12px;
}

.Row.Reversed {
  flex-direction: row-reverse;
}

/**
 * Column
 */

.Column_span_1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}
.Column_span_1_half {
  flex-basis: 12.49999998%;
  max-width: 12.49999998%;
}
.Column_span_2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}
.Column_span_2_half {
  flex-basis: 20.8333333%;
  max-width: 20.8333333%;
}
.Column_span_3 {
  flex-basis: 25%;
  max-width: 25%;
}
.Column_span_3_half {
  flex-basis: 29.16666665%;
  max-width: 29.16666665%;
}
.Column_span_4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}
.Column_span_4_half {
  flex-basis: 37.5%;
  max-width: 37.5%;
}
.Column_span_5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}
.Column_span_5_half {
  flex-basis: 45.8333333%;
  max-width: 45.8333333%;
}
.Column_span_6 {
  flex-basis: 50%;
  max-width: 50%;
}
.Column_span_6_half {
  flex-basis: 54.1666666%;
  max-width: 54.1666666%;
}
.Column_span_7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}
.Column_span_7_half {
  flex-basis: 62.5%;
  max-width: 62.5%;
}
.Column_span_8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}
.Column_span_8_half {
  flex-basis: 70.8333333%;
  max-width: 70.8333333%;
}
.Column_span_9 {
  flex-basis: 75%;
  max-width: 75%;
}
.Column_span_9_half {
  flex-basis: 79.1666666%;
  max-width: 79.1666666%;
}
.Column_span_10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}
.Column_span_10_half {
  flex-basis: 87.5%;
  max-width: 87.5%;
}
.Column_span_11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}
.Column_span_11_half {
  flex-basis: 95.8333333%;
  max-width: 95.8333333%;
}
.Column_span_12 {
  flex-basis: 100%;
  max-width: 100%;
}

.Column_offset_0 {
  margin-left: 0;
}

.Column_offset_1 {
  margin-left: 8.33333333%;
}
.Column_offset_1_half {
  margin-left: 12.49999998%;
}
.Column_offset_2 {
  margin-left: 16.66666667%;
}
.Column_offset_2_half {
  margin-left: 20.8333333%;
}
.Column_offset_3 {
  margin-left: 25%;
}
.Column_offset_3_half {
  margin-left: 29.16666665%;
}
.Column_offset_4 {
  margin-left: 33.33333333%;
}
.Column_offset_4_half {
  margin-left: 37.5%;
}
.Column_offset_5 {
  margin-left: 41.66666667%;
}
.Column_offset_5_half {
  margin-left: 45.8333333%;
}
.Column_offset_6 {
  margin-left: 50%;
}
.Column_offset_6_half {
  margin-left: 54.1666666%;
}
.Column_offset_7 {
  margin-left: 58.33333333%;
}
.Column_offset_7_half {
  margin-left: 62.5%;
}
.Column_offset_8 {
  margin-left: 66.66666667%;
}
.Column_offset_8_half {
  margin-left: 70.8333333%;
}
.Column_offset_9 {
  margin-left: 75%;
}
.Column_offset_9_half {
  margin-left: 79.1666666%;
}
.Column_offset_10 {
  margin-left: 83.33333333%;
}
.Column_offset_10_half {
  margin-left: 87.5%;
}
.Column_offset_11 {
  margin-left: 91.66666667%;
}
.Column_offset_11_half {
  margin-left: 95.8333333%;
}
.Column.NoPadding {
  padding: 0;
}

.Justify_start {
  justify-content: flex-start;
  text-align: start;
}

.Justify_center {
  justify-content: center;
  text-align: center;
}

.Justify_end {
  justify-content: flex-end;
  text-align: end;
}

.Align_start {
  align-items: flex-start;
}

.Align_center {
  align-items: center;
}

.Align_end {
  align-items: flex-end;
}

.Justify_around {
  justify-content: space-around;
}

.Justify_between {
  justify-content: space-between;
}

.Column {
  flex-direction: column;
  flex-grow: 1;
  padding: 0 var(--spacing-sl);
}
