.UploadLocationDocumentModal .ModalLayeredDropdown .LayeredSelect-dropdown,
.UploadLocationDocumentModal .ModalDropdown .MultiSelect-dropdown,
.UploadLocationDocumentModal .ModalDropdown .Select-dropdown {
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
}
.UploadLocationDocumentModal .ModalLayeredDropdown .LayeredSelect-dropdown .LayeredSelect-item-group {
  text-align: left;
}

.UploadLocationDocumentModal .ModalRadioButtons .Radio-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.UploadLocationDocumentModal .ModalFileUpload span {
  text-align: left;
}
