.ReadonlyText {
  width: 100%;
}

.ReadonlyText .LabelContainer {
  display: flex;
}

.ReadonlyText .LabelContainer.SpaceBetween {
  justify-content: space-between;
}

.ReadonlyTextLabel {
  display: block;
  margin-bottom: var(--spacing-s);
  margin-right: var(--spacing-s);
}

.ReadonlyNoDataAvailable {
  color: var(--color-grey-40);
}

.ReadonlyText .link {
  font-weight: 700;
  color: var(--color-blue);
}

.ReadonlyText .ReadonlyTextExternalLink {
  font-weight: 700;
  color: var(--color-blue);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.ReadonlyText .ReadonlyTextExternalLink:hover {
  text-decoration: underline;
}
