.ManageTags {
  margin-top: var(--spacing-ml);
}

.ManageTags .AddTagButton {
  margin-top: var(--spacing-m);
  min-width: unset;
}
.ManageTags .AddTagButton > svg > path {
  stroke: var(--color-blue);
}

.ManageTags .TagNameAndOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ManageTags .TagNameAndOptions p {
  width: 100%;
  margin: var(--spacing-s) 0;
}

.ManageTags .TagNameAndOptions p.Deleted {
  width: 100%;
  margin: var(--spacing-s) 0;
  text-decoration: line-through;
}

.ManageTags .TextInput {
  width: 100%;
}
.ManageTags .TextInput input {
  max-height: 40px;
}

.ManageTags .TagNameAndOptions .OptionsButton {
  min-height: unset;
  min-width: var(--spacing-3xl);
}

.ManageTags .TagNameAndOptions .OptionsButton svg {
  padding-left: unset;
}

.ManageTags .TagNameAndOptions .OptionsButton svg > circle {
  fill: var(--color-grey);
}
