.ProjectRoadmap {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-ll);
  max-height: 100%;
  height: fit-content;
}

.ProjectRoadmap .ProjectRoadmapContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: auto;
  max-height: 602px;
}

.ProjectRoadmap .ProjectRoadmapContainer.Hidden {
  display: none;
}

.ProjectRoadmap .ProjectRoadmapContainer div > div > div:nth-child(1) > div > div:nth-child(2) {
  overflow: hidden !important;
}

/* default translate graph */
.ProjectRoadmap .ProjectRoadmapContainer svg > g:nth-child(n + 2):nth-child(-n + 7) {
  transform: translateY(50.2px);
}

/* grid */
.ProjectRoadmap .ProjectRoadmapContainer svg > g:nth-child(2):not(.timeline) > rect {
  fill: none;
  stroke: none;
}
.ProjectRoadmap .ProjectRoadmapContainer svg > g:nth-child(2):not(.timeline) > path {
  fill: none;
  stroke: var(--color-grey-20);
  stroke-dasharray: 4;
}
/* text */
.ProjectRoadmap .ProjectRoadmapContainer svg > g:last-child > text {
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-body4);
  font-weight: 300;
}

/* bar */
.ProjectRoadmap .ProjectRoadmapContainer svg:nth-child(1) > g:nth-child(n + 3):nth-child(-n + 7):not(.timeline) > rect {
  fill-opacity: 0.1;
  stroke-opacity: 1;
  stroke-width: 1;
  height: 18px;
  transform: translateY(7.5px);
  cursor: pointer;
}

.ProjectRoadmap .ProjectRoadmapContainer svg:nth-child(1) > g:nth-child(n + 6):nth-child(-n + 7):not(.timeline) > rect {
  fill-opacity: 0.4; /* Default state for selected bars */
  stroke-opacity: 1;
}

.ProjectRoadmap
  .ProjectRoadmapContainer
  svg:nth-child(1)
  > g:nth-child(n + 6):nth-child(-n + 7):not(.timeline)
  > rect.select {
  fill-opacity: 0.5;
}

.ProjectRoadmap
  .ProjectRoadmapContainer
  svg:nth-child(1)
  > g:nth-child(n + 6):nth-child(-n + 7):not(.timeline)
  > rect.select:hover {
  fill-opacity: 0.8;
}

/* tooltip manipulation */
.ProjectRoadmap .ProjectRoadmapContainer .google-visualization-tooltip {
  opacity: 0 !important;
  border: none;
  height: 100%;
  z-index: 1;
}
.ProjectRoadmap .ProjectRoadmapContainer .google-visualization-tooltip[clone] {
  opacity: 0.85 !important;
}

.ProjectRoadmap .ProjectRoadmapContainer .CustomTooltip {
  height: 100%;
  min-width: 270px;
  background-color: white;
  border: 1px solid var(--color-grey-20);
  overflow: auto;
}

.ProjectRoadmap .ProjectRoadmapContainer .CustomTooltip .TooltipHeader {
  padding: 15.4px var(--spacing-m);
  border-bottom: 1px solid var(--color-grey-20);
}

.ProjectRoadmap .ProjectRoadmapContainer .CustomTooltip .TooltipContent {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-m);
  padding: var(--spacing-m);
}

.ProjectRoadmap .ProjectRoadmapContainer .CustomTooltip .TooltipContent p {
  margin: unset;
}

.ProjectRoadmap .ProjectRoadmapContainer .CustomTooltip .TooltipContent .EditDatesButton p {
  color: var(--color-blue);
  text-decoration: underline;
  cursor: pointer;
}

.ProjectRoadmap .ProjectRoadmapContainer .CustomTooltip .TooltipContent .EditDatesButton p:hover {
  color: var(--color-blue-80);
}
