.ProjectRoadmapRowLabels {
  height: fit-content;
  position: sticky;
  left: 0;
  z-index: 1;
}

.ProjectRoadmapRowLabels .RowLabel.Header {
  position: sticky;
  top: 0;
  padding: 15px 0;
}

.ProjectRoadmapRowLabels .RowLabel.Header h3 {
  font-size: var(--font-size-body2);
  width: 100%;
}

.ProjectRoadmapRowLabels .RowLabel {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-sl);
  align-items: center;
  padding: var(--spacing-s) 0 52.04px 0;
  background-color: var(--color-white);
  border-right: 3px solid var(--color-grey-20);
  border-bottom: 1px solid var(--color-grey-20);
}

.ProjectRoadmapRowLabels .RowLabel.Small {
  padding: var(--spacing-s) 0;
}

.ProjectRoadmapRowLabels .RowLabel a {
  /* padding: var(--spacing-m) var(--spacing-m) var(--spacing-m) 0; */
  color: var(--color-blue);
  text-decoration: underline;
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ProjectRoadmapRowLabels .RowLabel a:hover {
  color: var(--color-blue-60);
}

.ProjectRoadmapRowLabels .RowLabel p {
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
