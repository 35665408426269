.PhaseLifecycle .PhaseLifecycleHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--spacing-s);
}

.PhaseLifecycle .PhaseLifecycleHeader .Button {
  min-width: unset;
}

.PhaseLifecycle .PhaseLifecycleSteps {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
  padding: var(--spacing-s);
  background-color: var(--color-light-grey);
  background-color: var(--color-light-grey);
}

.PhaseLifecycle .PhaseLifecycleSteps .PhaseSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-s);
  padding: var(--spacing-m);
}

.PhaseLifecycle .PhaseLifecycleSteps .PhaseSection.Before,
.PhaseLifecycle .PhaseLifecycleSteps .PhaseSection.During {
  background-color: var(--color-white);
}

.PhaseLifecycle .PhaseLifecycleSteps .PhaseSection.Completed {
  background-color: var(--color-navbar-green);
}

.PhaseLifecycle .PhaseLifecycleSteps .PhaseSection p.body2 {
  margin-top: var(--spacing-s);
}

.PhaseLifecycle .PhaseLifecycleSteps .PhaseSection p.body5 {
  margin-top: 0;
}
.PhaseLifecycle .PhaseLifecycleSteps .PhaseSection.Before p.body2,
.PhaseLifecycle .PhaseLifecycleSteps .PhaseSection.During p.body2 {
  color: var(--color-blue);
}
.PhaseLifecycle .PhaseLifecycleSteps .PhaseSection.Completed p {
  color: var(--color-white);
  font-weight: 400;
}

.PhaseLifecycle .EmptyPhaseLifecycleContainer {
  padding: var(--spacing-s);
  background-color: var(--color-light-grey);
}

.PhaseLifecycle .EmptyPhaseLifecycleContainer .EmptyPhaseLifecycle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-white);
  padding: var(--spacing-ml) var(--spacing-m);
}

.PhaseLifecycle .EmptyPhaseLifecycleContainer .EmptyPhaseLifecycle p.body01 {
  font-weight: 400;
}
