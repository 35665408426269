.NotesPanelContent {
  margin-top: var(--spacing-ml);
}

.NotesPanelContent .NoNotes {
  color: var(--color-grey-60);
}

.NotesPanelContent .NoteHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-m);
}

.NotesPanelContent .NoteHeader p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.NotesPanelContent .NoteHeader .Date {
  margin-top: 0;
  color: var(--color-grey-40);
}

.NotesPanelContent .NoteContent {
  color: var(--color-grey-60);
  word-wrap: break-word;
}

.NotesPanelContent .NoteFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--spacing-l);
}

.NotesPanelContent .NoteFooter .Button {
  min-width: 30px;
}

.DeleteNoteButton {
  min-width: 92px;
  min-height: unset;
}

.DeleteNoteButton svg > path {
  stroke: var(--color-blue);
}
