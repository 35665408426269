.FlyoutPanel {
  height: 100vh;
  background: var(--color-white);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  padding: var(--spacing-ll) var(--spacing-2xl);
  padding-bottom: var(--spacing-2xl);
}
.FlyoutPanel.ExtraSmall {
  width: 31vw;
}
.FlyoutPanel.Small {
  width: 57vw;
}
.FlyoutPanel.Medium {
  width: 72vw;
}
.FlyoutPanel.Large {
  width: 87vw;
}
.FlyoutPanel.Open {
  transform: translateX(0);
}

.ClosePanelButtonWrapper button {
  padding: 0;
  min-width: 0;
}

.FlyoutPanel .HeaderArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.FlyoutPanel .ButtonsArea {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-ll);
  align-items: center;
}

.FlyoutPanel .ContentArea {
  margin-top: var(--spacing-ll);
  width: 100%;
  height: calc(100vh - 5 * var(--spacing-xl));
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}
.FlyoutPanel .ContentArea::-webkit-scrollbar {
  display: none;
}

.FlyoutPanel.ExtraSmall .ContentArea {
  margin-top: var(--spacing-m);
}

.FlyoutPanel .BtnLink {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  color: var(--color-blue);
  text-decoration: underline;
  margin-left: var(--spacing-xs);
}
