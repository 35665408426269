.TagProjects {
  margin-top: var(--spacing-ml);
}

.TagProjects .ProjectNameAndCheckbox {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-m);
  align-items: center;
}
