.EditColumnsPanelContent .EditColumnRow {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-s);
  padding: var(--spacing-mm) 0;
  cursor: pointer;
}

.EditColumnsPanelContent .EditColumnRow svg.HamburgerMenuIcon {
  padding-right: var(--spacing-s);
}
