.DataGrid .rdg {
  box-sizing: inherit;
  --rdg-header-background-color: #fff;
  --rdg-row-hover-background-color: #fff;
  --rdg-font-size: var(--font-size-body2);
  --rdg-header-row-height: 48px !important;
  --rdg-border-color: var(--color-grey-20);
  border: 0;
  block-size: inherit;
  overflow-y: hidden;
}

.DataGrid .rdg .rdg-cell {
  border-inline-end: 0;
  padding: 0 var(--spacing-m);
}

.DataGrid .rdg .rdg-row {
  --rdg-row-height: var(--spacing-xl) !important;
}

.DataGrid .rdg .rdg-cell[aria-selected="true"] {
  outline: 0;
}

.DataGrid .rdg .rdg-cell .left {
  text-align: left;
}

.DataGrid .rdg .rdg-cell .center {
  text-align: center;
}

.DataGrid .rdg .rdg-cell .right {
  text-align: right;
}

.DataGrid .rdg .rdg-header-row .rdg-cell {
  padding: 0;
  overflow: visible;
  contain: size style;
}

.DataGridHeaderColumnName {
  padding: 0 var(--spacing-m);
  border-bottom: 1px solid var(--color-grey-20);
  height: var(--spacing-xl); /* same as row height */
  line-height: var(--spacing-xl); /* same as row height */
  display: flex;
  align-items: center;
}

.DataGridHeaderColumnName.left {
  justify-content: left;
}

.DataGridHeaderColumnName.center {
  justify-content: center;
}

.DataGridHeaderColumnName.right {
  justify-content: right;
}

.DataGridHeaderFilterContainer {
  width: 90%;
  height: 29px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: var(--spacing-s) 0 var(--spacing-sl) 0;
  border: 1px solid var(--color-grey-20);
}

.DataGridHeaderFilterContainer .DataGridHeaderFilterIconContainer {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) var(--spacing-s);
  margin-right: -20px;
}

.DataGridHeaderFilterContainer .DataGridHeaderFilterIconContainer > svg:not(.DataGridHeaderFilterIcon) {
  width: 24px;
  height: 24px;
  margin-left: calc(-1 * var(--spacing-xs));
  margin-right: calc(-1 * var(--spacing-xs));
}

.DataGridHeaderFilter {
  height: 100%;
  width: 100%;
  padding: 0 var(--spacing-s);
  border: none;
  text-transform: lowercase;
}

.DataGridHeaderFilter::placeholder,
/* Style bellow is used to make placeholder not disappear when input is clicked, even though input is readOnly*/
.DataGridHeaderFilter[placeholder]:focus::-webkit-input-placeholder {
  color: var(--color-grey-20);
}

.DataGridHeaderFilter:read-only {
  cursor: pointer;
}

.DataGridHeaderFilter:placeholder-shown {
  line-height: var(--line-height-h4);
}

.DataGridHeaderFilter:focus::placeholder {
  color: transparent;
}

.DataGridHeaderColumnName_sortable {
  cursor: pointer;
}

.DataGridHeaderColumnName_sortable:not(:hover) .DataGridHeaderColumnName_SortIconContainer > svg,
.DataGridHeaderColumnName_sortable .DataGridHeaderColumnName_SortIconContainer.ColumnSorted {
  display: none;
}

.DataGridHeaderColumnName_SortIconContainer,
.DataGridHeaderColumnName_SortedColumnIconContainer {
  width: 16px;
  height: 16px;
  display: flex;
}

.DataGridHeaderColumnName_SortIconContainer > svg {
  width: 16px;
  height: 16px;
}

.DataGrid .rdg .rdg-cell .Pill {
  /* 48px row height - 24px pill height -> 12px top & bottom margin to fill the row */
  margin-top: var(--spacing-sl);
  width: 100%;
}

.DataGridContainer.InfiniteScroll {
  height: 100%;
}

.DataGridContainer.InfiniteScroll .DataGrid {
  height: 100%;
  overflow-y: auto;
}

.DataGridContainer .NoData {
  color: var(--color-grey-60);
}

.DataGridContainer .FilterButtonsContainer {
  position: static;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-l);
}

.DataGridContainer .FilterButtonsContainer > .Toggle {
  width: 140px;
}

.DataGridContainer .ExportButtonsContainer {
  display: flex;
  justify-content: flex-end;
}
.DataGridContainer .ExportButtonsContainer > .Column {
  max-width: max-content;
}

.DataGridContainer .BtnLink {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  color: var(--color-blue);
  text-decoration: underline;
  margin-top: var(--spacing-xxs);
}

.DataGrid .DataGridLink {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.DataGrid .DataGridLink a {
  text-decoration: underline;
  color: var(--color-blue);
}

.DataGrid .DataGridLink .body2 {
  text-overflow: ellipsis;
  overflow: hidden;
}

.DataGridButton .Button {
  padding: 0;
  min-height: auto;
}

.DataGridButton.left .Button {
  text-align: left;
}

.DataGridButton.center .Button {
  text-align: center;
}

.DataGridButton.right .Button {
  text-align: right;
}

.DataGrid .DataGridSelect .Select {
  margin-top: calc((var(--rdg-row-height) - 30px) / 2);
}

.DataGrid .DataGridSelect .Select-selector {
  height: 30px !important;
}

.DataGridSelect.left .Select-selector {
  text-align: left;
}

.DataGridSelect.center .Select-selector {
  text-align: center;
}

.DataGridSelect.right .Select-selector {
  text-align: right;
}

.DataGrid .DataGridSelect .Select-selection-item,
.DataGrid .DataGridSelect .Select-selection-placeholder {
  line-height: var(--line-height-h2) !important;
}

.DataGrid .DataGridInput input {
  height: 30px;
  line-height: var(--line-height-h2);
  margin-top: calc((var(--rdg-row-height) - var(--line-height-h2)) / 2);
}

.DataGrid .DataGridInput.left input {
  text-align: left;
}

.DataGrid .DataGridInput.center input {
  text-align: center;
}

.DataGrid .DataGridInput.right input {
  text-align: right;
}

.DataGridResultCount {
  color: var(--color-blue-40);
  margin-left: auto;
  margin-right: auto;
}

.DataGrid .DataGridIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.DataGrid .DataGridIconWrapper .DataGridIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DataGridContainer.ClickableRows .rdg-row:hover {
  background-color: var(--color-light-grey);
  cursor: pointer;
}
